@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;700&display=swap);
.YoutubeBackground_container__3Z801 {
  position: relative;
  overflow: hidden;
}

.YoutubeBackground_videoContainer__d0rd6 {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.YoutubeBackground_videoInnerContainer__33XVU {
  width: 100%;
  height: 100%;
}

.YoutubeBackground_overlay__1ZnDS {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
}
.YoutubeBackground_videoIframe__1rsnc {
  position: relative;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.styles_background__17co- {
  width: 100%;
  height: 100%;

  position: fixed;
  top: 0;
  left: 0;

  background-color: rgba(0, 0, 0, 0.6);

  z-index: 9999998;
}

.styles_panel__3-Krf {
  max-width: 488px;
  max-height: calc(100vh - 64px);
  overflow-y: auto;

  position: fixed;
  bottom: 32px;
  right: 32px;

  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
  line-height: 1.2;
  color: #3c4858;

  padding: 16px;

  border-radius: 4px;
  box-shadow: 0 10px 8px 0 rgba(18, 18, 18, 0.2);
  background-color: #ffffff;

  z-index: 9999999;
}

.styles_panel__3-Krf button {
  font-family: "Source Sans Pro", sans-serif;
  cursor: pointer;
}

.styles_notice__2MPNb p {
  margin-top: 8px;
}

.styles_notice__2MPNb p:first-child {
  margin-top: 0;
}

.styles_notice__2MPNb p a {
  color: #3d72ff;
  cursor: pointer;
}

.styles_noticeSeeMore__2VSiR {
  display: inline-block;

  text-decoration: none;
  color: #8492a6;

  padding-bottom: 4px;
  margin-top: 24px;

  border-bottom: 1px solid #8492a6;
}

.styles_noticeSeeMore__2VSiR:hover {
  color: #3d72ff;
  border-color: #3d72ff;
}

.styles_noticeActions__ZkcJp {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;

  margin-top: 48px;
}

.styles_noticeAccept__2vYk7 {
  height: 40px;

  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: white;

  padding: 0 32px;
  margin: 0;

  border: 0;
  border-radius: 4px;
  box-shadow: none;
  background-color: #3c4858;
}
.styles_noticeAccept__2vYk7:hover,
.styles_noticeAccept__2vYk7:focus {
  outline: 0;
  background-color: #0f1a2a;
}

.styles_noticeConfigure__1QvkJ {
  font-size: 14px;
  color: #8492a6;

  padding: 0;
  margin: 0;
  padding-bottom: 4px;

  border: 0;
  border-bottom: 1px solid #8492a6;
  box-shadow: none;
  background: none;
}
.styles_noticeConfigure__1QvkJ:hover,
.styles_noticeConfigure__1QvkJ:focus {
  outline: 0;
  color: #3d72ff;

  border-color: #3d72ff;
}

.styles_formGroup__30402 {
  position: relative;

  margin-top: 24px;
}
.styles_formGroup__30402 input {
  width: 1px;
  height: 1px;

  position: absolute;
  top: 0;
  left: 0;

  visibility: hidden;
  opacity: 0;
}
.styles_formGroup__30402 input + label {
  width: 51px;
  height: 30px;

  text-indent: -999em;
  overflow: hidden;

  position: relative;

  padding: 2px 2px 2px 23px;

  border-radius: 36.5px;
  background-color: #c1c7d0;
}
.styles_formGroup__30402 input + label::after {
  content: "";
  width: 26px;
  height: 26px;

  position: absolute;
  top: 50%;
  left: 2px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);

  border-radius: 100px;
  box-shadow: 0 10px 8px 0 rgba(18, 18, 18, 0.2);
  background-color: #ffffff;
}
.styles_formGroup__30402 input:checked + label {
  background-color: #3c4858;
}
.styles_formGroup__30402 input:checked + label::after {
  left: auto;
  left: initial;
  right: 2px;
}

.styles_formGroup__30402 > p {
  max-width: 85%;
}

.styles_formError__4bzWF {
  display: block;
  font-size: 12px;
  color: #b44b46;

  margin: 8px 0;
}

.styles_formGroupHeader__3xxiC {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.styles_formGroupHeader__3xxiC > p {
  font-size: 20px;
  font-weight: bold;
  color: #3c4858;
}

.styles_saveSettings__2jrR- {
  height: 40px;

  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: #3c4858;

  padding: 0 32px;
  margin: 24px 0 0 0;

  border-radius: 4px;
  border: 1px solid #3c4858;
}
.styles_saveSettings__2jrR-:hover,
.styles_saveSettings__2jrR-:focus {
  outline: 0;
  color: #0f1a2a;
  background-color: #e3e7ec;
  border-color: #0f1a2a;
}

