@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;700&display=swap");

.background {
  width: 100%;
  height: 100%;

  position: fixed;
  top: 0;
  left: 0;

  background-color: rgba(0, 0, 0, 0.6);

  z-index: 9999998;
}

.panel {
  max-width: 488px;
  max-height: calc(100vh - 64px);
  overflow-y: auto;

  position: fixed;
  bottom: 32px;
  right: 32px;

  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
  line-height: 1.2;
  color: #3c4858;

  padding: 16px;

  border-radius: 4px;
  box-shadow: 0 10px 8px 0 rgba(18, 18, 18, 0.2);
  background-color: #ffffff;

  z-index: 9999999;
}

.panel button {
  font-family: "Source Sans Pro", sans-serif;
  cursor: pointer;
}

.notice p {
  margin-top: 8px;
}

.notice p:first-child {
  margin-top: 0;
}

.notice p a {
  color: #3d72ff;
  cursor: pointer;
}

.noticeSeeMore {
  display: inline-block;

  text-decoration: none;
  color: #8492a6;

  padding-bottom: 4px;
  margin-top: 24px;

  border-bottom: 1px solid #8492a6;
}

.noticeSeeMore:hover {
  color: #3d72ff;
  border-color: #3d72ff;
}

.noticeActions {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-top: 48px;
}

.noticeAccept {
  height: 40px;

  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: white;

  padding: 0 32px;
  margin: 0;

  border: 0;
  border-radius: 4px;
  box-shadow: none;
  background-color: #3c4858;
}
.noticeAccept:hover,
.noticeAccept:focus {
  outline: 0;
  background-color: #0f1a2a;
}

.noticeConfigure {
  font-size: 14px;
  color: #8492a6;

  padding: 0;
  margin: 0;
  padding-bottom: 4px;

  border: 0;
  border-bottom: 1px solid #8492a6;
  box-shadow: none;
  background: none;
}
.noticeConfigure:hover,
.noticeConfigure:focus {
  outline: 0;
  color: #3d72ff;

  border-color: #3d72ff;
}

.formGroup {
  position: relative;

  margin-top: 24px;
}
.formGroup input {
  width: 1px;
  height: 1px;

  position: absolute;
  top: 0;
  left: 0;

  visibility: hidden;
  opacity: 0;
}
.formGroup input + label {
  width: 51px;
  height: 30px;

  text-indent: -999em;
  overflow: hidden;

  position: relative;

  padding: 2px 2px 2px 23px;

  border-radius: 36.5px;
  background-color: #c1c7d0;
}
.formGroup input + label::after {
  content: "";
  width: 26px;
  height: 26px;

  position: absolute;
  top: 50%;
  left: 2px;
  transform: translateY(-50%);

  border-radius: 100px;
  box-shadow: 0 10px 8px 0 rgba(18, 18, 18, 0.2);
  background-color: #ffffff;
}
.formGroup input:checked + label {
  background-color: #3c4858;
}
.formGroup input:checked + label::after {
  left: initial;
  right: 2px;
}

.formGroup > p {
  max-width: 85%;
}

.formError {
  display: block;
  font-size: 12px;
  color: #b44b46;

  margin: 8px 0;
}

.formGroupHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.formGroupHeader > p {
  font-size: 20px;
  font-weight: bold;
  color: #3c4858;
}

.saveSettings {
  height: 40px;

  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: #3c4858;

  padding: 0 32px;
  margin: 24px 0 0 0;

  border-radius: 4px;
  border: 1px solid #3c4858;
}
.saveSettings:hover,
.saveSettings:focus {
  outline: 0;
  color: #0f1a2a;
  background-color: #e3e7ec;
  border-color: #0f1a2a;
}
